import React from 'react';
import Loader from 'react-loader-spinner';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const driverViewerStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}

const buttonGroupStyle = {
  margin: '20px'
}

class SuggestedModification extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      searchValue: "",
      loading: true,
      dbShifts: {},
      selectedShift : "",
      endTime: "",
      changesSuggested: {},
      showSubmitForm: false
    }
  }

  componentDidMount() {
    //console.log("REDID MOUNT")
    var db = this.props.db;
    const shiftList = this.props.shiftList;
    const myID = this.props.myID;
    var refStr = this.props.refStr;
    var shiftArr = {}
    Promise.all(shiftList.map((shiftId) => {
        return new Promise(async(resolve, reject) => { shiftArr[shiftId] = {}

        var ref = db.ref(refStr+"/"+shiftId+"/infos");
        var snapshot = await ref.once("value")
        var values = snapshot.val();
        shiftArr[shiftId]['infos'] = values;

        ref = db.ref(refStr+"/"+shiftId+"/list/");
        snapshot = await ref.once("value")
        values = snapshot.val();
        if (values != null){
            shiftArr[shiftId]['list'] = values;
        }
        resolve();
        });
    })).then(() => {
        //console.log(shiftArr)
        this.setState({dbShifts: shiftArr, loading: false});
    })
    var changesSuggested = {}
      Promise.all(shiftList.map((shiftId) => {
          return new Promise(async(resolve, reject) => { 
          
          var refStr = "shiftChangeRequest/"+shiftId;
          var listRef = this.props.db.ref(refStr+"/list/");
          var snapshot = await listRef.once("value")
          var values = snapshot.val();
          if (values != null){
            changesSuggested[shiftId] = {}
            changesSuggested[shiftId]['list'] = values;
          }
          resolve();
          });
      })).then(() => {
          //console.log("changesSuggested",changesSuggested)
          this.setState({changesSuggested: changesSuggested, loading: false});
      })
  }

  shouldComponentUpdate(props, state){
    //console.log("RESHOULD UPDATE", this.props, props)
    if (this.props !== props || this.state.showSubmitForm !== state.showSubmitForm){
        var db = props.db;
        const shiftList = props.shiftList;
        const myID = props.myID;
        var refStr = props.refStr;
        var shiftArr = {}
        Promise.all(shiftList.map((shiftId) => {
            return new Promise(async(resolve, reject) => { shiftArr[shiftId] = {}

            var ref = db.ref(refStr+"/"+shiftId+"/infos");
            var snapshot = await ref.once("value")
            var values = snapshot.val();
            shiftArr[shiftId]['infos'] = values;

            ref = db.ref(refStr+"/"+shiftId+"/list/");
            snapshot = await ref.once("value")
            values = snapshot.val();
            if (values != null){
                shiftArr[shiftId]['list'] = {}
                shiftArr[shiftId]['list'][myID] = values;
            }
            resolve();
            });
        })).then(() => {
            //console.log(shiftArr)
            this.setState({dbShifts: shiftArr, loading: false});
        })

        var changesSuggested = {}
        Promise.all(shiftList.map((shiftId) => {
            return new Promise(async(resolve, reject) => { 
            
            var refStr = "shiftChangeRequest/"+shiftId;
            var listRef = this.props.db.ref(refStr+"/list/");
            var snapshot = await listRef.once("value")
            var values = snapshot.val();
            if (values != null){
              changesSuggested[shiftId] = {}
              changesSuggested[shiftId]['list'] = values;
            }
            resolve();
            });
        })).then(() => {
            //console.log("changesSuggested",changesSuggested)
            this.setState({changesSuggested: changesSuggested, loading: false});
        })

        
    }
    return true;
  }

  extractArrFromObj = (jsonObj) => {
    var arr = []
    for (var objKey in jsonObj){
      let newObj = jsonObj[objKey]
      newObj.infos['shiftKey'] = objKey
      arr.push(newObj);
    }
    return arr;
  }

  getDriverObjs = (arrShifts) => {
    var driverObjs = {}
    arrShifts.forEach(element => {
      const infos = element.infos;
      const driverShifts = element.list;
      const driverObjArr = this.appendShiftInfosToDriverShift(infos, driverShifts);
      //console.log("driverObjArr", element, infos, driverShifts, driverObjArr)
      driverObjArr.forEach(driverObj => {
        if (!(driverObj.key in driverObjs)){
          driverObjs[driverObj.key] = {}
          driverObjs[driverObj.key]['displayName'] = driverObj.displayName
          driverObjs[driverObj.key]['email'] = driverObj.email
          driverObjs[driverObj.key]['driverShifts'] = []
        }
        driverObjs[driverObj.key]['driverShifts'].push(driverObj)
      });
    });
    return driverObjs;
  }

  calculateTotalHours = (shiftArr) => {
    var totalHour = 0;
    shiftArr.forEach(element => {
      totalHour += element.mytime;
    });
    return totalHour;
  }

  appendShiftInfosToDriverShift = (infos, driverShifts) => {
    var driverObjArr = [];
    for (var driverShiftKey in driverShifts){
      var driverShift = driverShifts[driverShiftKey];
      if(driverShift.mytime !== undefined && driverShift.mytime !== 0){
        driverShift['date'] = infos.date;
        driverShift['workDay'] = infos.workDay;
        driverShift['key'] = driverShiftKey;
        driverShift['shiftKey'] = infos.shiftKey;
        driverObjArr.push(driverShift);
      }
    }
    return driverObjArr;
  }

  handleChange = (event) => {
    //console.log("SELECTED "+event.target.value)
    this.setState({selectedShift: event.target.value});
  };

  handleToChange = (event) => {
    //console.log("EndTime "+event.target.value)
    this.setState({endTime: event.target.value});
  };

  toggleShowForm = () => {
    this.setState({showSubmitForm: !this.state.showSubmitForm, loading: true});
  }

  submitChange = (changesSuggestedKey, myID, diffTotal, event) => {
    //console.log(event, changesSuggestedKey, myID, diffTotal)
    var refStr = "shiftChangeRequest/"+changesSuggestedKey;
    var shiftRefStr = "shifts/"+changesSuggestedKey;
    var listRef = this.props.db.ref(refStr+"/list/"+myID);
    var shiftRef = this.props.db.ref(shiftRefStr+"/list/"+myID);
    let changes = this.state.changesSuggested[changesSuggestedKey].list[myID];
    changes['changed'] = true

    shiftRef.set({
        displayName: changes.displayName, 
        email: changes.email, 
        myStartHour: changes.startTime,
        myEndHour: changes.endTime,
        mytime: diffTotal,
        transactionCompleted: false
    }).then(() => {
        listRef.set(changes)
        this.setState({loading: false})
    })
  }

  declineChange = (changesSuggestedKey, myID, diffTotal, event) => {
    //console.log(event, changesSuggestedKey, myID, diffTotal)
    var refStr = "shiftChangeRequest/"+changesSuggestedKey;
    var listRef = this.props.db.ref(refStr+"/list/"+myID);
    let changes = this.state.changesSuggested[changesSuggestedKey].list[myID];
    changes['changed'] = false
    listRef.set(changes)
    this.setState({loading: false})
  }

  updateValue = (changesSuggestedKey, myID, event) => {
    //console.log(event, changesSuggestedKey, myID)
    let current = this.state.changesSuggested;
    current[changesSuggestedKey].list[myID]['endTime'] = event.target.value;
    this.setState({
        changesSuggested: current
    })
  }

  driverObjsToHtmlObj = (driverObjs, searchName) => {
    var htmlObjs = [];
    var innerHtmlObj = [];
    if (this.state.changesSuggested !== null && Object.keys(this.state.changesSuggested).length !== 0){
      innerHtmlObj.push(<Button color="primary" variant="contained" onClick={this.toggleShowForm}>Refresh data</Button>);
      innerHtmlObj.push(<h3>Suggested changes</h3>);
      for (var changesSuggestedKey in this.state.changesSuggested){
      //const myID = this.props.myID;
          let changeSuggested = this.state.changesSuggested[changesSuggestedKey];
          //console.log("SUGGESTEDKEYS",changeSuggested)
          for (var myID in changeSuggested.list){
              let myChangeSuggested = changeSuggested.list[myID];
              //let changed = myChangeSuggested.changed;

              let hourStart = parseInt(myChangeSuggested.startTime.split(":")[0])
              let hourFinish = parseInt(myChangeSuggested.endTime.split(":")[0])
              let minuteStart = parseInt(myChangeSuggested.startTime.split(":")[1])
              let minuteFinish = parseInt(myChangeSuggested.endTime.split(":")[1])

              let diffHours = hourFinish-hourStart > 0 ? hourFinish-hourStart : 12+(hourFinish-hourStart);
              let diffMinutes = minuteFinish-minuteStart;

              let diffTotal = Number((diffHours + (diffMinutes/60)).toFixed(2))
              innerHtmlObj.push(
                  <p style={{color: "green", fontSize: "24px"}}> 
                      <strong style={{color:"white"}}>{myChangeSuggested.displayName + " : " }</strong>
                      { myChangeSuggested.workDay + " " + myChangeSuggested.date + " "+myChangeSuggested.startTime + "-" }
                      <TextField
                          label = "End Time"
                          placeholder = "13:45"
                          id = "endTime"
                          name = "endTime"
                          value = { myChangeSuggested.endTime }
                          onChange = {this.updateValue.bind(this,changesSuggestedKey,myID)}
                          style={{backgroundColor: "white", height: "100%", width: "70px", verticalAlign: "bottom"}}
                      />
                      { " ("+ diffTotal+"H)" }
                      {myChangeSuggested.changed !== undefined && myChangeSuggested.changed === true ? " (CONFIRMED) " :
                      myChangeSuggested.changed !== undefined && myChangeSuggested.changed === false ? 
                      <div style={{display: "inline"}}>
                        <span style={{color: "red"}}> (DECLINED)</span>
                      </div>:
                          <div style={{display: "inline"}}>
                              <Button color="primary" onClick={this.submitChange.bind(this, changesSuggestedKey, myID, diffTotal)} variant="contained" style={{marginLeft: "5px"}}>Confirm</Button>
                              <Button color="secondary" onClick={this.declineChange.bind(this, changesSuggestedKey, myID, diffTotal)} variant="contained"  style={{marginLeft: "5px"}}>Decline</Button>
                      
                          </div>
                      }
                      </p>);
          
          }
      }  
          
          
      htmlObjs.push(<div style={driverViewerStyle}>{innerHtmlObj}</div>);
    }
    if (this.state.changesSuggested == null || Object.keys(this.state.changesSuggested).length == 0) {
      htmlObjs.push(<div style={driverViewerStyle}>
        <h3>Suggested changes</h3>
        <h4>There are no suggested changes for this week</h4>
        </div>);
    }
    return htmlObjs;
  }

  render() {
    const extracted = this.extractArrFromObj(this.state.dbShifts);
    //console.log(extracted)
    const driverObjs = this.getDriverObjs(extracted);
    //console.log("driverObjs", driverObjs)
    const driverHtmlObj = this.driverObjsToHtmlObj(driverObjs, this.state.searchValue);

    //console.log("DB shifts", this.state.dbShifts)
    //console.log("changesSuggested", this.state.changesSuggested)
    return (
      <div>
        { this.state.loading ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
        />
        :
        <div>
          {driverHtmlObj}
        </div>
        
        }
      </div>
    );
  }
}

export default SuggestedModification;
