import React from 'react';
import Loader from 'react-loader-spinner';
import TextField from '@material-ui/core/TextField';

const driverViewerStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}


class DriverViewer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      searchValue: "",
      loading: true,
      dbShifts: {},
      driverProfiles: {}
    }
  }

  componentDidMount() {
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    ref.on("value", (snapshot) => {
      var values = snapshot.val();
      if (values){
        this.setState({dbShifts: values, loading: false});
      }
    }, function (errorObject) {
    });
    db.ref("local_users").on("value", (snapshot) => {
      var values = snapshot.val();
      if (values){
        this.setState({driverProfiles: values, loading: false});
      }
    })
  }

  handleChange(event) {
    this.setState({searchValue: event.target.value});
  }

  extractArrFromObj = (jsonObj) => {
    var arr = []
    for (var objKey in jsonObj){
      arr.push(jsonObj[objKey]);
    }
    return arr;
  }

  getDriverObjs = (arrShifts) => {
    var driverObjs = {}
    arrShifts.forEach(element => {
      const infos = element.infos;
      const driverShifts = element.list;
      const driverObjArr = this.appendShiftInfosToDriverShift(infos, driverShifts);
      driverObjArr.forEach(driverObj => {
        if (!(driverObj.key in driverObjs)){
          driverObjs[driverObj.key] = {}
          if (this.state.driverProfiles[driverObj.key] === undefined){
            driverObjs[driverObj.key]['displayName'] = driverObj.displayName
          } else {
            //console.log("DISPLAY NAME")
            //console.log(this.state.driverProfiles[driverObj.key])
            driverObjs[driverObj.key]['displayName'] = this.state.driverProfiles[driverObj.key].name
          }
          
          driverObjs[driverObj.key]['email'] = driverObj.email
          driverObjs[driverObj.key]['driverShifts'] = []
        }
        driverObjs[driverObj.key]['driverShifts'].push(driverObj)
      });
    });
    return driverObjs;
  }

  calculateTotalHours = (shiftArr) => {
    var totalHour = 0;
    shiftArr.forEach(element => {
      totalHour += element.mytime;
    });
    return totalHour;
  }

  appendShiftInfosToDriverShift = (infos, driverShifts) => {
    var driverObjArr = [];
    for (var driverShiftKey in driverShifts){
      var driverShift = driverShifts[driverShiftKey];
      if(driverShift.mytime !== undefined && driverShift.mytime !== 0){
        driverShift['date'] = infos.date;
        driverShift['workDay'] = infos.workDay;
        driverShift['key'] = driverShiftKey;
        driverObjArr.push(driverShift);
      }
    }
    return driverObjArr;
  }

  driverObjsToHtmlObj = (driverObjs, searchName) => {
    var htmlObjs = [];
    for (var driverObjKey in driverObjs){
      const driverObj = driverObjs[driverObjKey];
      var driverName = driverObj.displayName;
      if (driverName.replace(/\s/g,'').toLowerCase().includes(searchName.replace(/\s/g,'').toLowerCase())){
        var innerHtmlObj = [] 
        var email = driverObj.email;
        innerHtmlObj.push(<h3>{driverName}</h3>);
        var totalHour = this.calculateTotalHours(driverObj.driverShifts);
        innerHtmlObj.push(<h4>Total: {totalHour + " hours"}</h4>);
        driverObj['driverShifts'].forEach(element => { // eslint-disable-line no-loop-func
          innerHtmlObj.push(<p style={{color: "green"}}> <strong style={{color:"white"}}>{element.workDay + " " + element.date + " "} </strong>{element.myStartHour + "-" + element.myEndHour + " ("+ element.mytime+"H)"}</p>); 
        });
        innerHtmlObj.push(<small>{email}</small>);

        htmlObjs.push(<div style={driverViewerStyle}>{innerHtmlObj}</div>);
      }
    }
    return htmlObjs;
  }

  render() {
    const extracted = this.extractArrFromObj(this.state.dbShifts);
    const driverObjs = this.getDriverObjs(extracted);
    const driverHtmlObj = this.driverObjsToHtmlObj(driverObjs, this.state.searchValue);
    return (
      <div>
        { this.state.loading ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
        />
        :
        <div>
          <TextField
            id="filled-search"
            label="Search driver"
            type="search"
            margin="normal"
            variant="filled"
            style={{backgroundColor: "white"}}
            value={this.state.searchValue}
            onChange={this.handleChange.bind(this)}
          />
          {driverHtmlObj}
        </div>
        
        }
      </div>
    );
  }
}

export default DriverViewer;
