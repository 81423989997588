import React from 'react';
import Loader from 'react-loader-spinner';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const driverViewerStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}

const buttonGroupStyle = {
  margin: '20px'
}

class SelfShiftViewer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      searchValue: "",
      loading: true,
      dbShifts: {},
      selectedShift : "",
      endTime: "",
      changesSuggested: {},
      showSubmitForm: false
    }
  }

  componentDidMount() {
    //console.log("REDID MOUNT")
    var db = this.props.db;
    const shiftList = this.props.shiftList;
    const myID = this.props.myID;
    var refStr = this.props.refStr;
    var shiftArr = {}
    Promise.all(shiftList.map((shiftId) => {
        return new Promise(async(resolve, reject) => { shiftArr[shiftId] = {}

        var ref = db.ref(refStr+"/"+shiftId+"/infos");
        var snapshot = await ref.once("value")
        var values = snapshot.val();
        shiftArr[shiftId]['infos'] = values;

        ref = db.ref(refStr+"/"+shiftId+"/list/"+myID);
        snapshot = await ref.once("value")
        values = snapshot.val();
        if (values != null){
            shiftArr[shiftId]['list'] = {}
            shiftArr[shiftId]['list'][myID] = values;
        }
        resolve();
        });
    })).then(() => {
        //console.log(shiftArr)
        this.setState({dbShifts: shiftArr, loading: false});
    })
    var changesSuggested = {}
      Promise.all(shiftList.map((shiftId) => {
          return new Promise(async(resolve, reject) => { 
          
          var refStr = "shiftChangeRequest/"+shiftId;
          var listRef = this.props.db.ref(refStr+"/list/"+myID);
          var snapshot = await listRef.once("value")
          var values = snapshot.val();
          if (values != null){
            changesSuggested[shiftId] = {}
            changesSuggested[shiftId]['list'] = {}
            changesSuggested[shiftId]['list'][myID] = values;
          }
          resolve();
          });
      })).then(() => {
          //console.log("changesSuggested",changesSuggested)
          this.setState({changesSuggested: changesSuggested, loading: false});
      })
  }

  shouldComponentUpdate(props, state){
    //console.log("RESHOULD UPDATE", this.props, props)
    if (this.props !== props || this.state.showSubmitForm !== state.showSubmitForm){
        var db = props.db;
        const shiftList = props.shiftList;
        const myID = props.myID;
        var refStr = props.refStr;
        var shiftArr = {}
        Promise.all(shiftList.map((shiftId) => {
            return new Promise(async(resolve, reject) => { shiftArr[shiftId] = {}

            var ref = db.ref(refStr+"/"+shiftId+"/infos");
            var snapshot = await ref.once("value")
            var values = snapshot.val();
            shiftArr[shiftId]['infos'] = values;

            ref = db.ref(refStr+"/"+shiftId+"/list/"+myID);
            snapshot = await ref.once("value")
            values = snapshot.val();
            if (values != null){
                shiftArr[shiftId]['list'] = {}
                shiftArr[shiftId]['list'][myID] = values;
            }
            resolve();
            });
        })).then(() => {
            //console.log(shiftArr)
            this.setState({dbShifts: shiftArr, loading: false});
        })

        var changesSuggested = {}
        Promise.all(shiftList.map((shiftId) => {
            return new Promise(async(resolve, reject) => { 
            
            var refStr = "shiftChangeRequest/"+shiftId;
            var listRef = this.props.db.ref(refStr+"/list/"+myID);
            var snapshot = await listRef.once("value")
            var values = snapshot.val();
            if (values != null){
              changesSuggested[shiftId] = {}
              changesSuggested[shiftId]['list'] = {}
              changesSuggested[shiftId]['list'][myID] = values;
            }
            resolve();
            });
        })).then(() => {
            //console.log("changesSuggested",changesSuggested)
            this.setState({changesSuggested: changesSuggested, loading: false});
        })

        
    }
    return true;
  }

  extractArrFromObj = (jsonObj) => {
    var arr = []
    for (var objKey in jsonObj){
      let newObj = jsonObj[objKey]
      newObj.infos['shiftKey'] = objKey
      arr.push(newObj);
    }
    return arr;
  }

  getDriverObjs = (arrShifts) => {
    var driverObjs = {}
    arrShifts.forEach(element => {
      const infos = element.infos;
      const driverShifts = element.list;
      const driverObjArr = this.appendShiftInfosToDriverShift(infos, driverShifts);
      //console.log("driverObjArr", element, infos, driverShifts, driverObjArr)
      driverObjArr.forEach(driverObj => {
        if (!(driverObj.key in driverObjs)){
          driverObjs[driverObj.key] = {}
          driverObjs[driverObj.key]['displayName'] = driverObj.displayName
          driverObjs[driverObj.key]['email'] = driverObj.email
          driverObjs[driverObj.key]['driverShifts'] = []
        }
        driverObjs[driverObj.key]['driverShifts'].push(driverObj)
      });
    });
    return driverObjs;
  }

  calculateTotalHours = (shiftArr) => {
    var totalHour = 0;
    shiftArr.forEach(element => {
      totalHour += element.mytime;
    });
    return totalHour;
  }

  appendShiftInfosToDriverShift = (infos, driverShifts) => {
    var driverObjArr = [];
    for (var driverShiftKey in driverShifts){
      var driverShift = driverShifts[driverShiftKey];
      if(driverShift.mytime !== undefined && driverShift.mytime !== 0){
        driverShift['date'] = infos.date;
        driverShift['workDay'] = infos.workDay;
        driverShift['key'] = driverShiftKey;
        driverShift['shiftKey'] = infos.shiftKey;
        driverObjArr.push(driverShift);
      }
    }
    return driverObjArr;
  }

  handleChange = (event) => {
    //console.log("SELECTED "+event.target.value)
    this.setState({selectedShift: event.target.value});
  };

  handleToChange = (event) => {
    //console.log("EndTime "+event.target.value)
    this.setState({endTime: event.target.value});
  };

  toggleShowForm = () => {
    this.setState({showSubmitForm: !this.state.showSubmitForm});
  }

  submitChange = () => {
    //console.log("SELECTED "+ this.state.selectedShift);
    //console.log("EndTime "+this.state.endTime)
    if (this.state.selectedShift && this.state.endTime){
      const myID = this.props.myID;
      const displayName = this.props.displayName;
      const email = this.props.email;
      let shiftID = this.state.selectedShift;
      var refStr = "shiftChangeRequest/"+shiftID;
      var listRef = this.props.db.ref(refStr+"/list/"+myID);
      let shiftStartTime = this.state.dbShifts[shiftID].list[myID].myStartHour;
      let shiftWorkday = this.state.dbShifts[shiftID].infos.workDay;
      let shiftDate = this.state.dbShifts[shiftID].infos.date;
      listRef.set({"endTime": this.state.endTime, "displayName": displayName, "email": email, "key": myID, "workDay": shiftWorkday, "date": shiftDate, "startTime": shiftStartTime})
      this.setState({loading: false})
      this.toggleShowForm()
    }
  }

  driverObjsToHtmlObj = (driverObjs, searchName) => {
    var htmlObjs = [];
    for (var driverObjKey in driverObjs){
      const driverObj = driverObjs[driverObjKey];
      var driverName = driverObj.displayName;
      if (driverName.replace(/\s/g,'').toLowerCase().includes(searchName.replace(/\s/g,'').toLowerCase())){
        var innerHtmlObj = [] 
        var email = driverObj.email;
        innerHtmlObj.push(<h3>My shifts</h3>);
        var totalHour = this.calculateTotalHours(driverObj.driverShifts);
        innerHtmlObj.push(<h4>Total: {totalHour + " hours"}</h4>);
        driverObj['driverShifts'].forEach(element => { // eslint-disable-line no-loop-func
          innerHtmlObj.push(<p style={{color: "green"}}> <strong style={{color:"white"}}>{element.workDay + " " + element.date + " "} </strong>{element.myStartHour + "-" + element.myEndHour + " ("+ element.mytime+"H)"}</p>); 
        });
        innerHtmlObj.push(<small>{email}</small>);
        innerHtmlObj.push(<br/>);
        
        innerHtmlObj.push(
          );

        let menuItems = []
        menuItems.push(<MenuItem value="">Select A shift</MenuItem>)
        
        //console.log("DRIVERS", driverObj['driverShifts'])
        driverObj['driverShifts'].forEach(element => { // eslint-disable-line no-loop-func
          menuItems.push(<MenuItem value={element.shiftKey}>{element.workDay + " " + element.date + " " +element.myStartHour + "-" + element.myEndHour}</MenuItem>); 
        });
        
        var changeGroup = [];

        changeGroup.push(
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.selectedShift}
            onChange={this.handleChange}
            variant="standard" 
            displayEmpty
            style={{"backgroundColor": "white", minWidth:"100px", verticalAlign: "top", height: "100%"}}
          >
            {menuItems}
          </Select>)
        changeGroup.push(<TextField
          label = "End Time"
          placeholder = "13:45"
          style={{backgroundColor: "white", height: "100%", width: "100px", marginLeft:"10px"}}
          value={this.state.endTime}
          onChange={this.handleToChange}/>);
        changeGroup.push(<Button onClick={this.submitChange} variant="contained" color="primary" style={{ verticalAlign: "top", height: "100%", marginLeft:"10px"}}>Submit</Button>);
        
        if (Object.keys(this.state.changesSuggested).length !== 0){
          innerHtmlObj.push(<h3>Modification suggestions</h3>);
        }
        for (var changesSuggestedKey in this.state.changesSuggested){
          const myID = this.props.myID;
          let changeSuggested = this.state.changesSuggested[changesSuggestedKey];
          let myChangeSuggested = changeSuggested.list[myID];
          let changed = myChangeSuggested.changed;

          innerHtmlObj.push(
            <p style={changed === false ? {color: "green", fontSize: "24px"} : {color: "green", fontSize: "24px"}}> 
              <strong style={{color:"white"}}>{myChangeSuggested.workDay + " " + myChangeSuggested.date + " "} </strong>
              {myChangeSuggested.startTime + "-" }
              <span style={changed?{color:"green"}:{color:"orange"}}>{myChangeSuggested.endTime}</span>
              <span> {changed === true ? "(CHANGED)":""}</span>
              <span style={{color:"red"}}> {changed === false ? "(DECLINED)":""}</span>
            </p>);
        }

        if (!this.state.showSubmitForm){
          innerHtmlObj.push(<Button variant="contained" onClick={this.toggleShowForm} color="primary" style={buttonGroupStyle}>Suggest an end time</Button>);
        } else {
          innerHtmlObj.push(
            <div style={{height:"45px"}}>
              {changeGroup}
            </div>
          )
        }
        
        
        htmlObjs.push(<div style={driverViewerStyle}>{innerHtmlObj}</div>);
      }
    }
    if (driverObjs == null || Object.keys(driverObjs).length == 0) {
      htmlObjs.push(<div style={driverViewerStyle}>
        <h3>My shifts</h3>
        <h4>You don't have any shift for the week, select a shift and it will appear here.</h4>
        </div>);
    }
    return htmlObjs;
  }

  render() {
    const extracted = this.extractArrFromObj(this.state.dbShifts);
    //console.log(extracted)
    const driverObjs = this.getDriverObjs(extracted);
    //console.log("driverObjs", driverObjs)
    const driverHtmlObj = this.driverObjsToHtmlObj(driverObjs, this.state.searchValue);

    //console.log("DB shifts", this.state.dbShifts)
    //console.log("changesSuggested", this.state.changesSuggested)
    return (
      <div>
        { this.state.loading ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
        />
        :
        <div>
          {driverHtmlObj}
        </div>
        
        }
      </div>
    );
  }
}

export default SelfShiftViewer;
