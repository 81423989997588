import React from 'react';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel } from '@material-ui/core';

const driverViewerStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}

const menuItems = [
  <MenuItem value="">
    <em>Clear</em>
  </MenuItem>,
  <MenuItem value="XS">XS</MenuItem>,
  <MenuItem value="S">S</MenuItem>,
  <MenuItem value="M">M</MenuItem>,
  <MenuItem value="L">L</MenuItem>,
  <MenuItem value="XL">XL</MenuItem>
];

class DriverInfoViewer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      searchValue: "",
      loading: true,
      users: {},
      addEmailValue: ""
    }
  }

  componentDidMount() {
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    console.log(refStr)
    ref.on("value", (snapshot) => {
      var values = snapshot.val();
      if (values){
        console.log(values)
        this.setState({users: values, loading: false});
      }
    }, function (errorObject) {
    });
  }
  handleAddEmailChange(event) {
    this.setState({addEmailValue: event.target.value});
  }


  extractArrFromObj = (jsonObj) => {
    var arr = []
    for (var objKey in jsonObj){
      let obj = jsonObj[objKey]
      arr.push(obj);
    }
    return arr;
  }

  handleCarSizeChange = (uid, event) => {
    console.log(uid, event.target.value)
    let tagValue = event.target.value;
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    ref.child(uid+"/carSize").set(tagValue);
  }

  driverInfosToHtmlObj = (driverInfos, label) => {
    let innerHtmlObj = []
    innerHtmlObj.push(<h3>{driverInfos.length} {label}</h3>);
    driverInfos.forEach(element => { // eslint-disable-line no-loop-func
        innerHtmlObj.push(
        <p style={{color: "white", fontSize: "24px", margin: "0.8em"}}> 
          <strong style={{color: "white", fontSize: "28px"}}>{`${element.name}`}</strong>
          <FormControl style={{"backgroundColor": "white", minWidth:"75px", verticalAlign: "bottom", height: "100%", marginLeft: "10px"}}>
            <InputLabel id="carSizeSelect">CarSize</InputLabel>
            <Select
              labelId="carSizeSelect"
              id="carSizeSelect"
              value={element.carSize}
              onChange={this.handleCarSizeChange.bind(this, element.uid)}
              variant="standard" 
              label="CarSize"
              //displayEmpty
            >
              {menuItems}
            </Select>
          </FormControl>
          <br/>
          {` ${element.email} `} 
          <br/>
          <span>{`last connexion: ${element.lastConnected}`}</span> 
        </p>); // eslint-disable-line jsx-a11y/anchor-is-valid, eqeqeq, no-script-url
    });
    return <div style={driverViewerStyle}>{innerHtmlObj}</div>;
      
  }

  render() {
    const label = this.props.label;
    const extracted = this.extractArrFromObj(this.state.users);
    const  driverHtmlObj = this.driverInfosToHtmlObj(extracted, label);
    console.log(extracted);
    return (
      <div>
        { this.state.loading ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
        />
        :
        <div>
          {driverHtmlObj}
        </div>
        
        }
      </div>
    );
  }
}

export default DriverInfoViewer;
