import React from 'react';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';
import TextField from '@material-ui/core/TextField';
import Autosuggest from 'react-autosuggest';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';

const buttonStyle = {
  margin: '5px'
}

const shiftViewerStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}

const hourToButtonNumber = {
  0: 0,
  2: 1,
  3: 2,
  4: 3
}

function accentAndCaseRemover(initialString){
  if (initialString){
    const replacementDict = {
      'a': ['â','à','ä','Â','À','Ä'],
      'e': ['é','ê','ë','è','É','È','Ê','Ë'],
      'c': ['ç','Ç'],
      'i': ['î','ï','ì','Î','Ï','Ì'],
      'o': ['ô','ö','ò','Ô','Ö','Ò'],
      'u': ['û','ü','ù','Û','Ü','Ù'],
      '':['-', ' ']
    }
    for (var key in replacementDict){
      replacementDict[key].forEach(
        specialLetter => {
          initialString = initialString.replace(new RegExp(specialLetter, 'g'),key);
        }
      );
    }
  
    initialString = initialString.toLowerCase();
  }
  return initialString
}

const menuItems = [
  <MenuItem value="">
    <em>Clear</em>
  </MenuItem>,
  <MenuItem value="pickup">Pickup</MenuItem>,
  <MenuItem value="exclusive">Exclusive</MenuItem>,
  <MenuItem value="reserve">Reserve</MenuItem>,
  <MenuItem value="cancelled">Cancelled</MenuItem>
];

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div style={{verticalAlign: 'center'}}>
    {suggestion.name + " ("+suggestion.email+")"}
  </div>
);

class ShiftViewer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      yo: "tototot",
      totalTime: 50,
      myID: "zl1234",
      buttonSelected: 0,
      loading: true,
      secondaryButton: [],
      myStartHour: "",
      myEndHour: "",
      startHourButtonId: -1,
      mytime: 0,
      startHourSelected: "",
      startHours:[],
      shiftLengths: [],
      modifyButtonClicked: false,
      shiftList: {},
      suggestionResults: [],
      results: [],
      search: "",
      changedMyStartHour: "",
      changedMyEndHour: "",
      changedMyTime: "",
      addHoursBack: false
    }
  }

  onChangeSearch = (event, { newValue }) => {
    this.setState({
      search: newValue,
      name: newValue
    });
  }

  onSelected = (event, { suggestion}) => {
    this.setState({
      name: suggestion.name,
      selectedId: suggestion.id,
      email: suggestion.email,
    })
  }

  getSuggestions = (value, list, listEmails) => {
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId);
    }

    var emailSet = new Set(listEmails)

    this.lastRequestId = setTimeout(()=>{
      const inputValue = accentAndCaseRemover(value.trim().toLowerCase());
      const inputLength = inputValue.length;
      const listToReturn = inputLength < 2 ? [] : list.filter(lang =>
        lang.name && lang.email ?
        accentAndCaseRemover(lang.name.toLowerCase()).slice(0, inputLength) === inputValue && emailSet.has(lang.email)
        : false
        ); 
      this.setState({suggestionResults: listToReturn.slice(0,5)});
    }, 330
    );
    
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value, this.props.listMember, this.props.listEmails);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestionResults: []
    });
  };

  shouldComponentUpdate(props, state){
    if (this.props !== props){
      var db = props.db;
      var refStr = props.refStr;
      var ref = db.ref(refStr);
      ref.child("infos").on("value", (snapshot) => {
        var values = snapshot.val();
        if (values){
          values.loading = false;
          this.setState(values);
        }
      }, function (errorObject) {
      });
      ref.child('list').on("value", (snapshot) =>{
        const value = snapshot.val();
        if (value){
          this.setState({
            shiftList: value
          });
        }
        else {
          this.setState({
            shiftList: []
          });
        }
      });
    }
    return true;
  }

  componentDidMount() {
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    ref.child("infos").on("value", (snapshot) => {
      var values = snapshot.val();
      if (values){
        values.loading = false;
        this.setState(values);
      }
    }, function (errorObject) {
    });
    ref.child('list').on("value", (snapshot) =>{
      const value = snapshot.val();
      if (value){
        this.setState({
          shiftList: value
        });
      }
      else {
        this.setState({
          shiftList: []
        });
      }
    });

  }


  addZeroToSingleDigitHours = (hour) => {
    if (hour >= 0 && hour <= 9){
      return "0"+hour;
    }
    return ""+hour;
  }

  takeshift = (n, buttonNb, startHour, myEndHour) => {
    const myID = this.props.myID;

    var refStr = this.props.refStr;
    var listRef = this.props.db.ref(refStr+"/list/"+myID);
    this.setState({buttonSelected: buttonNb, mytime:n});
    listRef.set({"mytime":n, myStartHour: startHour, myEndHour:myEndHour})
  }

  emptyButtonAndTakeShift = (n, buttonNb, startHour, myEndHour) => {
    this.setState({secondaryButton: [], startHourSelected: "", startHourButtonId: -1, modifyButtonClicked: false});
    this.takeshift(n, buttonNb, startHour, myEndHour);
  }
  refreshAndTakeShift = async (startHour, endHour, n, buttonNb, myEndHour) => {
    await this.emptyButtonAndTakeShift(n, buttonNb, startHour, myEndHour);
  }

  createButton = (startHour, endHour, startHourButtonId) => {
    return <Button variant="contained" color={startHourButtonId === this.state.startHourButtonId ? "secondary" :"primary"} onClick={this.createToTimeButton.bind(this, startHour, endHour, startHourButtonId)} style={buttonStyle}>
             {startHour}
           </Button>
  }

  stringTimeAddHour = (strTime, addedHour) => {
    var hourNb = parseFloat(strTime.slice(0,2));
    var finalHour = (hourNb + addedHour) % 24;
    var minutesStr = strTime.slice(3,5);

    return this.addZeroToSingleDigitHours(finalHour) + ":" + minutesStr;
  }

  createToTimeButton = (startHour, endHour, startHourButtonId) => {
    const remainingTime = this.state.remainingTime;
    const mytime = this.state.mytime;
    const shiftLengths = this.state.shiftLengths;
    var choices = shiftLengths.filter((number)=>{
      return number <= remainingTime + mytime;
    });
    var currentStartHour = this.state.myStartHour;
    var startHourNb = parseFloat(startHour.slice(0,2));
    var startMinutesStr = startHour.slice(3,5);
    var startMinutesNb = parseFloat(startMinutesStr);
    var arrButtons = [];
    for(var choice of choices){
      var endHourNb = parseFloat(endHour.slice(0,2));
      var endMinutesNb = parseFloat(endHour.slice(3,5));
      var endHourCalculated = startHourNb + choice;
      
      if (endHourCalculated > endHourNb) continue;
      else if(endHourCalculated === endHourNb && endMinutesNb > startMinutesNb) continue;

      var button = 
      <Button variant="contained" disabled={this.state.buttonSelected === hourToButtonNumber[choice] && currentStartHour !== "" && startHour === currentStartHour} color="primary" onClick={this.refreshAndTakeShift.bind(this, startHour, endHour ,choice, hourToButtonNumber[choice], endHourCalculated+":"+startMinutesStr)} style={buttonStyle}>
        {this.addZeroToSingleDigitHours(endHourCalculated)+":"+startMinutesStr+" ("+choice+"h)"}
      </Button>

      arrButtons.push(button);
    }
    this.setState({secondaryButton: arrButtons, startHourButtonId: startHourButtonId, startHourSelected: startHour});
  }

  getSnapshotBeforeUpdate(nextProps, nextState) {
    if (this.state.startHourSelected !== "" && nextState.remainingTime !== this.state.remainingTime){
      this.createToTimeButton(nextState.startHourSelected, nextState.endTime, nextState.startHourButtonId);
    }
  }

  createShiftStartTimeButtons = (startHours) => {
    var startHoursButtons = [];
    for(var startTime of startHours){
      startHoursButtons.push(this.createButton(startTime, this.state.endTime, startTime));
    }
    return startHoursButtons;
  }

  toggleModifyButton = () => {
    var currentModifyState = this.state.modifyButtonClicked;
    this.setState({modifyButtonClicked: !currentModifyState});
  }

  isRemainingTimeLessThanMinimum(remainingTime,shiftLengths){
    return remainingTime < Math.min(...shiftLengths);
  }

  isCurrentDateEarlierThanShiftDate(shiftDate){
    if (shiftDate !== undefined){
      var shiftDateArr = shiftDate.split("-")
      shiftDateArr[1] = shiftDateArr[1]-1;
      var shiftDateObj = new Date(...shiftDateArr);
      var now = new Date();
      return now < shiftDateObj;
    }
    return false;
  }

  calculateTotalHours = (shiftArr) => {
    var totalHour = 0;
    shiftArr.forEach(element => {
      totalHour += element.mytime;
    });
    return totalHour;
  }

  calculateTotalDrivers = (shiftArr) => {
    var totalDrivers = 0;
    shiftArr.forEach(element => {
      if (element.mytime > 0){ // 0 hour is when someone drop a shift
        totalDrivers += 1;
      }
    });
    return totalDrivers;
  }

  handleTagChange = (uid, event) => {
    console.log(uid, event)
    let tagValue = event.target.value;
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    ref.child("list/"+uid+"/tag").set(tagValue);
  }

  shiftListToHTMLObj = (shiftList) => {
    var shiftArr = [];
    var htmlObjects = [];
    for (var shiftKey in shiftList) {
      let shift = shiftList[shiftKey];
      shift["uid"] = shiftKey;
      shiftArr.push(shift);
    } 

    shiftArr.sort((a, b) => {
      if (a.displayName < b.displayName) return -1;
      else if (a.displayName > b.displayName) return 1;
      return 0;
    });
    
    if (shiftArr.length > 0) {
      const totalHour = this.calculateTotalHours(shiftArr);
      const totalDrivers = this.calculateTotalDrivers(shiftArr);
      if (totalDrivers > 1){
        htmlObjects.push(<h3>{totalDrivers} drivers</h3>)
      }
      else {
        htmlObjects.push(<h3>{totalDrivers} driver</h3>)
      }
      if (totalHour > 0){
        htmlObjects.push(<h3>Total hours taken: <strong style={{color:"green"}}>{totalHour}</strong></h3>)

      }
    }
    for (var shift of shiftArr){
      if (shift.mytime > 0){
        console.log(shift.tag)
        let carSize = ""
        if (shift.carSize != null && shift.carSize !== ""){
          carSize = ` (${shift.carSize}) `
        }
        htmlObjects.push(
            <p style={{color:"green"}} >
              <FormControl style={{"backgroundColor": "white", minWidth:"100px", verticalAlign: "top", height: "100%", marginRight: "10px"}}>
                <InputLabel id="tagSelect">Tag</InputLabel>
                <Select
                  labelId="tagSelect"
                  id="tagSelect"
                  value={shift.tag}
                  onChange={this.handleTagChange.bind(this, shift.uid)}
                  variant="standard" 
                  label="Tag"
                  //displayEmpty
                >
                  {menuItems}
                </Select>
              </FormControl>
              <strong style={{color:"white"}}>{ shift.displayName +carSize+ ": "}</strong>
              {shift.myStartHour + " - " + shift.myEndHour + " (" + shift.mytime + "H)"}
              <div style={{display: "inline-block", marginLeft:"5px"}}>
              {
                shift.transactionCompleted ?
                <a title="Cancel shift" style={{color:"red", textDecoration: "None"}} onClick={this.clearShift.bind(this, shift.displayName, shift.email, shift.uid, shift.mytime)} href="javascript:void(0);">x</a> // eslint-disable-line jsx-a11y/anchor-is-valid, eqeqeq, no-script-url
                :
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={100000} //100 secs
                />
              }
              </div>
            </p>
        );
      }
    }

    return htmlObjects;
  }
  handleFromChange(event) {
    this.setState({changedMyStartHour: event.target.value});
  }
  handleToChange(event) {
    this.setState({changedMyEndHour: event.target.value});
  }
  handleTimeChange(event) {
    this.setState({changedMyTime: event.target.value});
  }

  submit = () => {
    var data = {
      displayName: this.state.name,
      email: this.state.email,
      myStartHour: this.state.changedMyStartHour,
      myEndHour: this.state.changedMyEndHour,
      mytime: parseFloat(this.state.changedMyTime),
      transactionCompleted: false
    };
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    ref.child("list/"+this.state.selectedId).update(data).then(()=>{
      this.setState({
        modifyButtonClicked: false
      })
    });
  }

  clearShift = (displayName, email, uid, myTime) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <div className="react-confirm-alert-overlay">
              <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                  <h1>Confirm the delete</h1>
                  <p>Are you sure to delete {displayName}'s shift?</p>
                  <p>
                    Add {myTime} hours back to the scheduler
                    <input
                      type="checkbox"
                      defaultChecked={this.state.addHoursBack}
                      color="primary"
                      onChange={(event) => {this.setState({addHoursBack: event.target.checked})}}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      size="large"
                    />
                  </p>
                  <div className="react-confirm-alert-button-group">
                    <button onClick={() => {
                      var data = {
                        displayName: displayName,
                        email: email,
                        myStartHour: "",
                        myEndHour: "",
                        mytime: 0,
                        transactionCompleted: false,
                        addHoursBack: this.state.addHoursBack
                      };
                      var db = this.props.db;
                      var refStr = this.props.refStr;
                      var ref = db.ref(refStr);
                      ref.child("list/"+uid).update(data).then(()=>{
                        this.setState({
                          modifyButtonClicked: false
                        })
                        this.setState({addHoursBack: false})
                      });
                      onClose()
                    }}>
                      Yes
                    </button>
                    <button onClick={() => {onClose()}}>
                      No
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
        );
      }
    });
  } 
  emptyButtonClick = () => {
    confirmAlert({
      title: 'Confirm the clear',
      message: 'Are you sure to clear all remaining hours?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var db = this.props.db;
            var refStr = this.props.refStr;
            var ref = db.ref(refStr);
            ref.child("infos/remainingTime").set(0);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  } 
  
  render() {
    if(this.state.workDay !== undefined && !(this.state.workDay).startsWith(this.props.selectedWorkday)){
      return "";
    }
    var renderedShiftList = []
    if(this.state.shiftList !== undefined){
      renderedShiftList = this.shiftListToHTMLObj(this.state.shiftList);
    }

    const { search, suggestionResults } = this.state;
    const inputProps = {
      placeholder: 'Driver Name',
      value: search,
      onChange: this.onChangeSearch
    };

    const submitReady = this.state.selectedId !== undefined && this.state.selectedId !== "" &&
    this.state.name !== undefined && this.state.name !== "" &&
    this.state.email !== undefined && this.state.email !== "" &&
    this.state.changedMyStartHour !== undefined && this.state.changedMyStartHour !== "" &&
    this.state.changedMyEndHour !== undefined && this.state.changedMyEndHour !== "" &&
    this.state.changedMyTime !== undefined && this.state.changedMyTime !== "";

    return (
      <div style={shiftViewerStyle}>
      {
        this.state.loading ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
        />
        :
      <div>
        <h3> {this.state.date} </h3>
        <h4> {this.state.workDay} from {this.state.startTime} to {this.state.endTime} </h4>
        { renderedShiftList.length > 0 ? 
        <div style={shiftViewerStyle}>
          {renderedShiftList}
        </div>
        : ""}
        
        <p>
          {this.state.remainingTime > 0  ? this.state.remainingTime + " hours still"  : "No more hours"}  available 
          {this.state.remainingTime > 0  ?
            <a title="Cancel shift" style={{color:"red", marginLeft:"5px", textDecoration: "None"}} onClick={this.emptyButtonClick.bind(this)} href="javascript:void(0);">x</a> 
            : ""}
          </p> 
        
        { this.state.modifyButtonClicked ? 
          <div style={{width: "246px", margin: "auto"}}>
            <Autosuggest
                suggestions={suggestionResults}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={this.onSelected}
              />
            <div style={{width: "80px", display: "inline-block"}}>
            <TextField 
              label = "Start Time"
              placeholder = "09:00"
              style={{backgroundColor: "white"}}
              value={this.state.searchValue}
              onChange={this.handleFromChange.bind(this)}/>
            </div>
            <div style={{width: "80px", display: "inline-block", marginLeft: "5px"}}>
            <TextField
              label = "End Time"
              placeholder = "13:45"
              style={{backgroundColor: "white"}}
              value={this.state.searchValue}
              onChange={this.handleToChange.bind(this)}/>
            </div>
            <div style={{width: "70px", display: "inline-block", marginLeft: "5px"}}>
            <TextField
              label = "Hours"
              placeholder = "4.75"
              style={{backgroundColor: "white"}}
              value={this.state.searchValue}
              onChange={this.handleTimeChange.bind(this)}/>
            </div>
            <Button style={{marginTop: "5px"}} variant="contained" color="secondary" onClick={this.toggleModifyButton.bind(this)}>Cancel</Button>
            <Button disabled={submitReady ? false : true}style={{marginTop: "5px", marginLeft: "5px"}} variant="contained" color="primary" onClick={this.submit.bind(this)}>Submit</Button>
          </div>
        : 
          <Button style={{marginTop: "5px"}} variant="contained" color="primary" onClick={this.toggleModifyButton.bind(this)}>Add/Modify</Button>
          
        }
      </div>
      }
      </div>
    );
  }
}

export default ShiftViewer;
