import React from 'react';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const driverViewerStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}


class DriverEmailViewer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      searchValue: "",
      loading: true,
      emails: {},
      addEmailValue: ""
    }
  }

  componentDidMount() {
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    console.log(refStr)
    ref.on("value", (snapshot) => {
      var values = snapshot.val();
      if (values){
        console.log(values)
        this.setState({emails: values, loading: false});
      }
    }, function (errorObject) {
    });
  }
  handleAddEmailChange(event) {
    this.setState({addEmailValue: event.target.value});
  }

  submit = (event) => {
    event.preventDefault();
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    ref.child(this.state.addEmailValue.replace(/ /g,"").toLowerCase().replace(/\./g, "%2E")).set(true).then(()=>{
      this.setState({
        addEmailValue: "",
        modifyButtonClicked: false
      })
    });
  }

  extractArrFromObj = (jsonObj) => {
    var arr = []
    for (var objKey in jsonObj){
      arr.push(objKey.replace(/%2E/g, "."));
    }
    return arr;
  }

  clearEmail = (email, label) => {
    confirmAlert({
      title: 'Remove from '+ label,
      message: 'Are you sure to remove '+ email+" from "+label+"?",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var db = this.props.db;
            var refStr = this.props.refStr;
            var ref = db.ref(refStr);
            var emailKey = email.replace(/\./g, "%2E")
            console.log(emailKey);
            ref.child(emailKey).remove().then(()=>{
              this.setState({
                modifyButtonClicked: false
              })
            });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  driverEmailsToHtmlObj = (driverEmails, label) => {
    let innerHtmlObj = []
    innerHtmlObj.push(<h3>{driverEmails.length} {label}</h3>);
    innerHtmlObj.push(<div>
      <form onSubmit={this.submit.bind(this)}>
        <TextField 
          placeholder = "Email"
          style={{backgroundColor: "white"}}
          value={this.state.addEmailValue}
          onChange={this.handleAddEmailChange.bind(this)}/>
        <Button disabled={this.addEmailValue === "" ? true : false} style={{marginLeft: "5px", verticalAlign: "top"}} variant="contained" color="primary" onClick={this.submit.bind(this)}>Add</Button>
      </form>
    </div>)
    driverEmails.forEach(element => { // eslint-disable-line no-loop-func
        innerHtmlObj.push(<p style={{color: "white", fontSize: "24px", margin: "0.5em"}}> {element} <a title="Remove authorization" style={{color:"red", textDecoration: "None"}} onClick={this.clearEmail.bind(this, element, label)} href="javascript:void(0);">x</a>  </p>); // eslint-disable-line jsx-a11y/anchor-is-valid, eqeqeq, no-script-url
    });
    return <div style={driverViewerStyle}>{innerHtmlObj}</div>;
      
  }

  render() {
    const label = this.props.label;
    const extracted = this.extractArrFromObj(this.state.emails);
    const  driverHtmlObj = this.driverEmailsToHtmlObj(extracted, label);
    console.log(extracted);
    return (
      <div>
        { this.state.loading ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
        />
        :
        <div>
          {driverHtmlObj}
        </div>
        
        }
      </div>
    );
  }
}

export default DriverEmailViewer;
