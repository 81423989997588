import React from 'react';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';

class SubscribeList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        loading: true,
        isSubscribed: false
    }
  }

  shouldComponentUpdate(props, state){
    if (this.props !== props){
        var db = props.db;
        const dayStr = props.dayStr;
        const myID = props.myID;
        var refStr = props.refStr;
        const myEmail = props.myEmail;
        //console.log("did mount", props.dayStr)
        var ref = db.ref(refStr+"/"+dayStr+"/"+myID);
        ref.on("value", snapshot => {
            var value = snapshot.val();
            //console.log(value)
            if (value != null){
                //console.log("I AM SUBSCRIBED")
                this.setState({
                    isSubscribed: true,
                    loading: false
                })
            } else {
                this.setState({
                    isSubscribed: false,
                    loading: false
                })
            }
        }, error => {
            //console.log("ERROR")
            this.setState({
                isSubscribed: false,
                loading: false
            })
        })
    }
    return true;
  }

  componentDidMount() {
    var db = this.props.db;
    const dayStr = this.props.dayStr;
    const myID = this.props.myID;
    var refStr = this.props.refStr;
    const myEmail = this.props.myEmail;
    //console.log("did mount", this.props.dayStr)
    var ref = db.ref(refStr+"/"+dayStr+"/"+myID);
    ref.on("value", snapshot => {
        var value = snapshot.val();
        //console.log(value)
        if (value != null){
            //console.log("I AM SUBSCRIBED")
            this.setState({
                isSubscribed: true,
                loading: false
            })
        } else {
            this.setState({
                isSubscribed: false,
                loading: false
            })
        }
    }, error => {
        //console.log("ERROR")
        this.setState({
            isSubscribed: false,
            loading: false
        })
    })
  }

  subscribe = () => {
    //console.log("SUB!", this.props.dayStr)
    var db = this.props.db;
    const dayStr = this.props.dayStr;
    const myID = this.props.myID;
    var refStr = this.props.refStr;
    const myEmail = this.props.myEmail;
    const myName = this.props.myName;

    var ref = db.ref(refStr+"/"+dayStr+"/"+myID);
    this.setState({loading:true})
    ref.set({name: myName, email: myEmail}).then(this.setState({loading:false}));
  }

  unsub = () => {
    //console.log("UNSUB!", this.props.dayStr)
    var db = this.props.db;
    const dayStr = this.props.dayStr;
    const myID = this.props.myID;
    var refStr = this.props.refStr;
    const myEmail = this.props.myEmail;

    var ref = db.ref(refStr+"/"+dayStr+"/"+myID);
    this.setState({loading:true})
    ref.remove().then(this.setState({loading:false}));
  }

  render() {
    let dateStr = this.props.dayStr;
    let dateArr = dateStr.split("-")
    dateArr[1] = dateArr[1]-1;
    let date = new Date(...dateArr)
    let selectedDay = this.props.selectedWorkdayNo
    //console.log (date.getDay(), date, selectedDay)

    return (<div style={{marginTop:"50px"}}>
        
        {date.getDay() === selectedDay ? 
            this.state.loading === false ? 
            <div>
                {this.state.isSubscribed ? 
                <Button variant="contained" color="secondary" onClick={this.unsub.bind(this)}>Remove me from Stand-By Drivers</Button>
                : <Button variant="contained" color="primary" onClick={this.subscribe.bind(this)}>Add me to Stand-By Drivers</Button>            
                }
                <p>Add yourself and we will email you if we need <br/>more drivers for this calendar day ({dateStr})</p>
            </div>
            :
            <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={100000} //100 secs
            />
        :""}
        
    </div>);
  }
}

export default SubscribeList;
