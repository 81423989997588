import React from 'react';
import logo from './boxknight_logo.png';
import './App.css';
import * as firebase from 'firebase';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';
import ShiftSelector from './components/shiftSelector';
import * as firebaseui from 'firebaseui';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import Konami from 'react-konami-code';
import purple from '@material-ui/core/colors/purple';
import ShiftViewer from './components/shiftViewer';
import DriverViewer from './components/driverViewer';
import firebaseConfig from './firebaseConfig.json';
import DriverEmailViewer from './components/driverEmails';
import city from './city.json';
import ExportView from './components/exportView';
import { Checkbox } from '@material-ui/core';
import SelfShiftViewer from './components/selfShiftViewer';
import SuggestedModification from './components/suggestedModification';
import SubscribeList from './components/subscribeList';
import SubscribeListViewer from './components/subscribeListViewer';
import DriverInfoViewer from './components/driverInfos';
import _ from 'lodash';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db = firebase.database();

const buttonStyle = {
  margin: '2px'
}
const buttonGroupStyle = {
  margin: '20px'
}

const appContainerStyle = {
  marginTop: "50px",
  marginBottom: "100px",
  marginLeft: "20px",
  marginRight: "20px"
}

const powerButtonStyle = {
  color: "white",
  backgroundColor: purple[500]
}

const smallWorkDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const uiConfig = {
  signInFlow: "popup",
  credentialHelper: firebaseui.auth.CredentialHelper.NONE, 
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  signInSuccessUrl: "/",
  // Other config options...
}

const todayDate = new Date();


class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedDay: smallWorkDays[todayDate.getDay()],
      shiftList: [],
      loading: true,
      authenticated: false,
      myID: "",
      displayName: "",
      email: "",
      unauthorisedMessage: "",
      adminView: false,
      perDriverView: false,
      driverEmailView: false,
      driverInfosView: false,
      hideData: false,
      hideForNormal: false,
      shiftRefStr: "shiftList",
      listMember: [],
      listEmails: [],
      exportView: false,
      availableShiftNotifchecked: false,
      shiftReminderNotifChecked: true,
      showMyShifts: false,
      suggestedChange: false,
      carSize:"",
      selectedShiftId:"",
      holidayShiftLists: {},
      selectedWeek: ""
    }
  }
  
  easterEgg = () => {
  }
 
  handleWorkdayButtonClick = (workday) => {
    this.setState({selectedDay: workday.slice(0,3)})
  }

  shiftListIdsToDates = (shiftList) => {
    var dateArr = []
    shiftList.forEach((elem) => {
      var dateStr = elem.slice(0,10);
      if (!dateArr.includes(dateStr)){
        dateArr.push(dateStr);
      }
    });
    dateArr.sort();
    return dateArr;
  }

  getDateObjFromDateStr = (dateStr) => {
    var shiftDateArr = dateStr.split("-")
    shiftDateArr[1] = shiftDateArr[1]-1;
    var shiftDateObj = new Date(...shiftDateArr);
    return shiftDateObj
  }

  getWorkdayFromDateStr = (dateStr) => {
    var shiftDateObj = this.getDateObjFromDateStr(dateStr);
    var weekdayNb = shiftDateObj.getDay();
    return smallWorkDays[weekdayNb];
  }

  changeSelectedShiftId = (shiftId) => {
    this.setState({selectedShiftId: shiftId});
  }

  createWorkdayButtons = () => {
    var workDaysButtons = [];
    const dateArr = this.shiftListIdsToDates(this.state.shiftList);
    var buttonTexts = []
    dateArr.forEach((elem) => {
      buttonTexts.push(this.getWorkdayFromDateStr(elem) +" (" +elem.slice(8,10)+")"); 
    })
    buttonTexts.forEach((workday, index)=>{
      var dateStr = dateArr[index];
      var todayStr = this.constructDateStringFromDate(todayDate);
      const styleVariant = todayStr <= dateStr ? "contained" : "outlined";
      workDaysButtons.push(
        <Button variant={styleVariant} style={buttonStyle} color={this.state.selectedDay === workday.slice(0,3)? "secondary":"default"} onClick={this.handleWorkdayButtonClick.bind(this, workday)}>{workday}</Button>
      );
    });
    return workDaysButtons;
  }

  handleCheckChange = () => {
    this.setState({availableShiftNotifchecked: !this.state.availableShiftNotifchecked})
    const dbRef = firebase.database().ref('emailNotif');
    const selfRef = dbRef.child(this.state.myID);
    selfRef.update({
      name: this.state.displayName,
      email: this.state.email,
      uid: this.state.myID,
      availableShiftNotif: !this.state.availableShiftNotifchecked
    });
  }

  handleReminderCheckChange = () => {
    this.setState({shiftReminderNotifChecked: !this.state.shiftReminderNotifChecked})
    const dbRef = firebase.database().ref('emailNotif');
    const selfRef = dbRef.child(this.state.myID);
    selfRef.update({
      name: this.state.displayName,
      email: this.state.email,
      uid: this.state.myID,
      shiftReminderNotif: !this.state.shiftReminderNotifChecked
    });
  }

  updateData(refStr){
    var ref = db.ref(refStr);
    var that = this; // this reference for error message
    ref.on("value", (snapshot) => {
      var values = snapshot.val();
      this.setState({shiftList: values, loading: false, shiftRefStr: refStr});
      this.updateSelfShiftList(values, "shifts");
    }, function (errorObject) {
      var unauthorisedMessage = <h3>You're not allowed to see the shifts, please contact us at <a style={{color:"gray"}}href="mailto:driver@boxknight.com">driver@boxknight.com</a></h3>
      that.setState({unauthorisedMessage: unauthorisedMessage, loading: false, shiftList:[]});
    });
  }

  updateSelfShiftList = (shiftList, allShiftRef) => {
    var myID = this.state.myID;
    //console.log("MYID", myID)
    this.setState({loading: true})
    if (myID != null){
      Promise.all(shiftList.map((shiftId) => {
        return new Promise(async(resolve, reject) => { 
          var ref = db.ref(allShiftRef+"/"+shiftId+"/infos");
          ref.once("value", snapshot=>{
            let shift = {}
            var values = snapshot.val();
            if (values != null){
              shift['date'] = values.date;
              shift['shiftId'] = shiftId;
  
              ref = db.ref(allShiftRef+"/"+shiftId+"/list/"+myID);
              ref.on("value", shiftSnap => {
                let shiftValues = shiftSnap.val();
                if (shiftValues != null && shiftValues.mytime != null){
                    shift['myStartHour'] = shiftValues.myStartHour
                    shift['myEndHour'] = shiftValues.myEndHour
                }
                this.setState({[shiftId]: shift})
                resolve();
              })
            }
          })
        });
      })).then(
      () => {
        this.setState({loading: false})
      });
    }
  }

  async componentDidMount() {
    var refStr = this.state.shiftRefStr;
    var holidayRefStr = "holidayShiftLists";
    var refColdStart = "shifts/wakeup/list/wakeup"
    var hideDataRefStr = "hideData";
    var hideNormalRefStr = "hideForNormal";
    var ref = db.ref(refStr);
    var hideDataRef = db.ref(hideDataRefStr);
    var coldStartRef = db.ref(refColdStart);
    var hideNormalRef = db.ref(hideNormalRefStr);
    var holidayRef = db.ref(holidayRefStr);
    coldStartRef.remove();
    coldStartRef.set({yo: new Date().toISOString()});
    coldStartRef.update({yo: new Date().toISOString()});
    var that = this; // this reference for error message
    var hasHolidayList = false;

    await holidayRef.once("value", (snap)=>{
      var holidayList = snap.val();
      if (holidayList != null){
        hasHolidayList = true; // if there is a holiday list, we can display the holiday shift list
      }
      var key = "";
      
      var now = todayDate;
      for (var key in holidayList){
        var holidayShiftList = holidayList[key]['list'];
        if(this.isWeekdayStrInArr(this.constructDateStringFromDate(now), holidayShiftList)){
          let nextState = holidayRefStr +"/"+key+"/list";
          this.updateData(nextState);
          this.setState({shiftList: holidayShiftList, holidayShiftLists: holidayList, selectedWeek: key});
          this.updateSelfShiftList(holidayShiftList, "shifts");
        }else {
          this.setState({loading: false});
        }
      }
    }, function (errorObject) {
      var unauthorisedMessage = <h3>You're not allowed to see the shifts, please contact us at <a style={{color:"gray"}}href="mailto:driver@boxknight.com">driver@boxknight.com</a></h3>
      that.setState({unauthorisedMessage: unauthorisedMessage, loading: false});
    });

    if (!hasHolidayList){
      ref.on("value", (snapshot) => {
        var values = snapshot.val();
        this.setState({shiftList: values});
        this.updateSelfShiftList(values, "shifts");
        if (values.length > 0){
          this.setState({loading: true});
          var now = todayDate;
          if(!this.isWeekdayStrInArr(this.constructDateStringFromDate(now), values)){
            var nextState = "shiftList" === refStr ? "shiftListNextWeek" : "shiftList";
            this.updateData(nextState);
          }
          else {
            this.setState({loading: false});
          }
        }
      }, function (errorObject) {
        var unauthorisedMessage = <h3>You're not allowed to see the shifts, please contact us at <a style={{color:"gray"}}href="mailto:driver@boxknight.com">driver@boxknight.com</a></h3>
        that.setState({unauthorisedMessage: unauthorisedMessage, loading: false});
      });
    }

    hideDataRef.on("value", (snapshot) => {
      var values = snapshot.val();
      if (values === true){
        var unauthorisedMessage = <h3>Shifts cannot be reserved at this time. The Scheduler will be unlocked Wednesday at 5pm.</h3>
        this.setState({hideData: values, loading: false, unauthorisedMessage: unauthorisedMessage});
      }
    }, function (errorObject) {
      var unauthorisedMessage = "";
      that.setState({unauthorisedMessage: unauthorisedMessage, loading: false});
    });

    
    hideNormalRef.on("value", (snapshot) => {
      var values = snapshot.val();
      if (values === true){
        var unauthorisedMessage = <h3>Shifts cannot be reserved at this time. The Scheduler will be unlocked Wednesday at 5pm.</h3>
        this.setState({hideForNormal: values, loading: false, unauthorisedMessage: unauthorisedMessage});
      }
    }, function (errorObject) {
      console.log("VALUE ERROR")
      var unauthorisedMessage = "";
      that.setState({unauthorisedMessage: unauthorisedMessage, loading: false, shiftList: []});
    });

    const datatRef = db.ref('local_users');
    datatRef.once("value", (snap)=>{
      var listMember = [];
      snap.forEach(function(childSnapshot) {
        var childData = childSnapshot.val();
        childData['id'] = childSnapshot.key;
        listMember.push(childData);
      });
      this.setState({listMember: listMember});
    });

    const emailRef = db.ref('authorizedEmails');
    emailRef.on("value", (snap)=>{
      var listEmails = [];
      snap.forEach(function(childSnapshot) {
        var email = childSnapshot.key;
        email = email.replace(/%2E/g, ".")
        listEmails.push(email);
      });
      this.setState({listEmails: listEmails});
    });

    

    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        const dbRef = firebase.database().ref('local_users');
        const selfRef = dbRef.child(user['uid']);
        const dateTimeString = this.constructDateHourStringFromDate(new Date());
        selfRef.child('carSize').once('value',(snap)=>{
          let carSize = snap.val()
          this.setState({carSize: carSize})
        })
        this.setState({authenticated: true, loading: true, myID: user['uid'], email: user['email'], displayName: user['displayName']});
        selfRef.update({
          name: user['displayName'],
          email: user['email'],
          uid: user['uid'],
          lastConnected: dateTimeString
        });

        const datatRef = db.ref('emailNotif/'+user['uid']);
        datatRef.once("value", (snap)=>{
          //console.log("EMAIL NOTIF", snap.val())
          let availableShiftNotif = snap.val() != null && snap.val().availableShiftNotif === true
          let shiftReminderNotif = snap.val() == null || (snap.val() != null && (snap.val().shiftReminderNotif == null || snap.val().shiftReminderNotif === true)); // default to true if not set
          this.setState({
            availableShiftNotifchecked: availableShiftNotif,
            shiftReminderNotifChecked: shiftReminderNotif
          });
        });
      }
    });
  }

  getMyCurrentShiftsFromState() {
    let shiftList = this.state.shiftList;
    //console.log("currentShiftList", shiftList)
    //console.log("state", this.state)
    let myShiftList = [];
    shiftList.forEach(shiftId => {
      let singleShift = this.state[shiftId]
      if (singleShift != null && singleShift.myStartHour != null && singleShift.myStartHour != ""){
        myShiftList.push(singleShift);
      }
    });
    return myShiftList;
  }

  createShiftsSelectors = (currentSelfShifts) => {
    var shiftSelectors = [];
    (this.state.shiftList).forEach((shift) => {
      shiftSelectors.push(<ShiftSelector displayName={this.state.displayName} email={this.state.email} refStr={"shifts/"+shift} allShiftRef={"shifts"} db={db} myID={this.state.myID} selectedWorkday={this.state.selectedDay} carSize={this.state.carSize} shiftList={this.state.shiftList} selectedShiftId={this.state.selectedShiftId} changeSelectedShiftId={this.changeSelectedShiftId} shiftId={shift} currentSelfShifts={currentSelfShifts}/>);
    });
    shiftSelectors.push(this.createSubscribeListObjs());
    return shiftSelectors;
  }

  createSubscribeListObjs = () => {
    var subscribeListDay = [];
    var uniqueDays = new Set();
    (this.state.shiftList).forEach((shift) => {
      let dayStr = shift.slice(0,10);
      if (!uniqueDays.has(dayStr)){
        uniqueDays.add(dayStr);
        //console.log(dayStr);
        subscribeListDay.push(
          <SubscribeList db={db} refStr="subscribeList" myID={this.state.myID} myEmail={this.state.email} dayStr={dayStr} myName={this.state.displayName} selectedWorkdayNo={smallWorkDays.indexOf(this.state.selectedDay)}/>
        )
      }
    });
    return subscribeListDay;
  }

  createSubscribeViewObjs = () => {
    var subscribeListDay = [];
    var uniqueDays = new Set();
    (this.state.shiftList).forEach((shift) => {
      let dayStr = shift.slice(0,10);
      if (!uniqueDays.has(dayStr)){
        uniqueDays.add(dayStr);
        //console.log(dayStr);
        subscribeListDay.push(
          <SubscribeListViewer db={db} refStr="subscribeList"  dayStr={dayStr} selectedWorkdayNo={smallWorkDays.indexOf(this.state.selectedDay)}/>
        )
      }
    });
    return subscribeListDay;
  }

  createShiftsViewers = () => {
    var shiftViewers = [];
    (this.state.shiftList).forEach((shift) => {
      shiftViewers.push(<ShiftViewer refStr={"shifts/"+shift} db={db} listMember={this.state.listMember} listEmails={this.state.listEmails} myID={this.state.myID} selectedWorkday={this.state.selectedDay}/>);
    });
    shiftViewers.push(this.createSubscribeViewObjs());
    return shiftViewers;
  }

  signInWithEmail = () => {
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', uiConfig); 
  }

  logOut = () => {
    firebase.auth().signOut().then(()=>{
      this.setState({authenticated: false});
    })
  }

  toggleAdminView = () => {
    this.setState({adminView:!this.state.adminView});
  }

  toggleEmailView = () => {
    this.setState({driverEmailView:!this.state.driverEmailView});
  }

  toggleInfosView = () => {
    this.setState({driverInfosView:!this.state.driverInfosView});
  }
  
  toggleExportView = () => {
    this.setState({exportView:!this.state.exportView});
  }
  toggleSuggestedChanges = () => {
    this.setState({suggestedChange:!this.state.suggestedChange});
  }
  
  togglePerDriverView = () => {
    this.setState({perDriverView:!this.state.perDriverView});
  }

  isWeekdayStrInArr = (dateStr, shiftList) => {
    const dateArr = this.shiftListIdsToDates(shiftList);
    return dateArr.includes(dateStr);
  }

  toggleWeeklyView = () => {
    var nextState = "shiftList" === this.state.shiftRefStr ? "shiftListNextWeek" : "shiftList";
    this.setState({shiftRefStr: nextState})
    
    var refStr = nextState;
    var ref = db.ref(refStr);
    ref.once("value", (snapshot) => {
    var values = snapshot.val();
      this.setState({shiftList: values, loading: false});
      this.updateSelfShiftList(values, "shifts");
    }, function (errorObject) {
    });
  }

  toggleShowMyShifts = () => {
    this.setState({showMyShifts:!this.state.showMyShifts});
  }

  constructDateStringFromDate = (date) => {
    var dateYear = date.getFullYear();
    var dateMonth = date.getMonth() + 1; //months are from 0-11
    dateMonth = dateMonth < 10 ? "0"+dateMonth : dateMonth;
    var dateDay = date.getDate();
    dateDay = dateDay < 10 ? "0"+dateDay : dateDay;

    return dateYear + "-" + dateMonth + "-" + dateDay;
  }

  constructDateHourStringFromDate = (date) => {
    var mm = date.getMonth() + 1;
    if (mm < 10) mm = "0" + mm;
    var dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    var hh = date.getHours();
    if (hh < 10) hh = "0" + hh;
    var mn = date.getMinutes();
    if (mn < 10) mn = "0" + mn;

    return date.getFullYear() + '-' + mm + '-' + dd + ' ' + hh + ':' + mn;
  }

  selectHoliday = (holidayShiftList, key) => {
    let nextState = "holidayShiftLists/"+key+"/list";
    this.updateData(nextState);
    this.setState({shiftList: holidayShiftList, selectedWeek:key});
    this.updateSelfShiftList(holidayShiftList, "shifts");
  }

  generateButtonFromHolidaySelection = (holidayShiftLists) => {
    var buttons = [];
    var i = 0;
    
    var maxShownElements = 4;
    var index = 0;
    var listLenght = Object.keys(holidayShiftLists).length; 
        
          

    for(var key in holidayShiftLists){
      console.log(index, maxShownElements, listLenght)
      if (index >= (listLenght - maxShownElements)){
        var holidayShiftList = holidayShiftLists[key]['list'];
        var holidayShiftName = holidayShiftLists[key]['name'];
        buttons.push(<Button variant="contained" color={this.state.selectedWeek == key? "secondary" :"primary"} style={{marginLeft:"5px"}} key={i} onClick={this.selectHoliday.bind(this, holidayShiftList, key)}>{holidayShiftName}</Button>);
        i++;
      }
      index += 1;
    }
    //console.log(buttons);
    return buttons;
    
  }

  render() { 
    let currentSelfShifts = this.getMyCurrentShiftsFromState()
    //console.log(this.state.holidayShiftLists)
    return (
      <div className="App">
        <header className="App-header">
          {
          this.state.loading ? 
          <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={10000} //10 secs
          />
          :
          <div style={appContainerStyle}>
            <h3>{city.cityName} Scheduler</h3>
            <img src={logo} className="App-logo" alt="logo" />
            { this.state.authenticated ? 
              <div>
                <h3>Welcome {this.state.displayName.split(" ")[0]}!</h3>
                {this.state.hideData && this.state.hideForNormal || this.state.shiftList.length == 0 ? this.state.unauthorisedMessage :
                <div>
                <Konami action={this.easterEgg}>
                  <p>You have unlocked the admin view!</p>
                  { this.state.adminView ? 
                    ""
                    :
                    <p><Button style={powerButtonStyle} onClick={this.toggleAdminView.bind(this)} variant="contained" color="primary" ><span role="img" aria-label="fire">🔥</span>Give me infinite power!<span role="img" aria-label="fire">🔥</span></Button></p>}
                  
                </Konami>

                {this.state.adminView?
                this.state.driverEmailView ?
                <div>
                  <p><Button onClick={this.toggleEmailView.bind(this)} variant="contained" color="primary" > Back to shifts </Button></p>
                </div> :
                this.state.driverInfosView ?
                <div>
                  <p><Button onClick={this.toggleInfosView.bind(this)} variant="contained" color="primary" > Back to shifts </Button></p>
                </div> :
                this.state.exportView ?
                <div>
                  <p><Button onClick={this.toggleExportView.bind(this)} variant="contained" color="primary" > Back to shifts </Button></p>
                </div> :
                this.state.suggestedChange ?
                <div>
                  <p><Button onClick={this.toggleSuggestedChanges.bind(this)} variant="contained" color="primary" > Back to shifts </Button></p>
                </div> :
                this.state.perDriverView ?
                <div>
                  <p><Button onClick={this.toggleAdminView.bind(this)} variant="contained" color="primary" ><span role="img" aria-label="snowman">☃️</span> Cool me down! <span role="img" aria-label="snowman">☃️</span></Button></p>
                  <p><Button onClick={this.togglePerDriverView.bind(this)} variant="contained" color="primary" ><span role="img" aria-label="snow">❄️️️</span>Ok chill chill!<span role="img" aria-label="snowm">❄️</span></Button></p>
                  
                </div>
                :
                <div>
                  <p><Button onClick={this.toggleSuggestedChanges.bind(this)} variant="contained" color="primary" > Suggested changes </Button></p>
                  <p><Button onClick={this.toggleExportView.bind(this)} variant="contained" color="primary" > Export </Button></p>
                  <p><Button onClick={this.toggleEmailView.bind(this)} variant="contained" color="primary" > Driver Emails </Button> <Button onClick={this.toggleInfosView.bind(this)} variant="contained" color="primary" > Driver Infos </Button></p>
                  <p><Button onClick={this.toggleAdminView.bind(this)} variant="contained" color="primary" ><span role="img" aria-label="snowman">☃️</span> Cool me down! (Back to shift selection)<span role="img" aria-label="snowman">☃️</span></Button></p>
                  <p><Button style={powerButtonStyle} onClick={this.togglePerDriverView.bind(this)} variant="contained" color="primary" ><span role="img" aria-label="fire">🔥</span>Even more power! (All shifts)<span role="img" aria-label="fire">🔥</span></Button></p>
                </div>
                :""}
                <div style={{marginBottom: "20px"}}>
                  {/*<Checkbox
                    checked={this.state.availableShiftNotifchecked}
                    color="primary"
                    onChange={this.handleCheckChange.bind(this)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  /> <span onClick={this.handleCheckChange.bind(this)} style={{fontSize: "18px"}}>Notify me by email when there are new shifts available</span> <br/>
                  */}
                  <Checkbox
                    checked={this.state.shiftReminderNotifChecked}
                    color="primary"
                    onChange={this.handleReminderCheckChange.bind(this)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  /> <span onClick={this.handleReminderCheckChange.bind(this)} style={{fontSize: "18px"}}>Notify me by email of my shifts</span> <br/>
                </div>
                <p><Button variant="contained" color="primary" onClick={this.toggleShowMyShifts.bind(this)}>
                  {this.state.showMyShifts === false ? "My shifts" : "Shift selector"}
                </Button></p>
                { _.isEmpty(this.state.holidayShiftLists) ? 
                <p><Button variant="contained" color="primary" onClick={this.toggleWeeklyView.bind(this)}>
                  {this.state.shiftRefStr === "shiftList" ? "Next Week" : "Last Week"}
                  </Button></p> 
                : this.generateButtonFromHolidaySelection(this.state.holidayShiftLists)}
                <div style={buttonGroupStyle}>
                  {
                    (this.state.perDriverView || this.state.driverEmailView || this.state.driverInfosView || this.state.exportView) && this.state.adminView ? "" :
                    this.createWorkdayButtons()
                  }
                </div>
                {
                  this.state.adminView ? 
                    this.state.driverEmailView ?
                    <div><DriverEmailViewer db={db} refStr="priorityEmails" label="Priority Access Emails"/> 
                    <DriverEmailViewer db={db} refStr="authorizedEmails" label="Authorized Emails"/> </div>
                    :
                    this.state.driverInfosView ?
                    <DriverInfoViewer db={db} refStr="local_users" label="Registered Users"/>
                    :
                    this.state.exportView ?
                    <ExportView/> 
                    :
                    this.state.suggestedChange ?
                    <SuggestedModification db={db} refStr="shifts" shiftList={this.state.shiftList} myID={this.state.myID} displayName={this.state.displayName} email={this.state.email}/>
                    :
                    this.state.perDriverView ?
                      <DriverViewer db={db} refStr="shifts"/> 
                    :
                    this.createShiftsViewers()
                  :
                    
                    this.state.showMyShifts === false ? 
                   
                    this.createShiftsSelectors(currentSelfShifts)
                     :
                    <SelfShiftViewer db={db} refStr="shifts" shiftList={this.state.shiftList} myID={this.state.myID} displayName={this.state.displayName} email={this.state.email}/>
                }
                </div>}
                
                <Button style={{marginTop:"50px"}} variant="contained" color="primary" onClick={this.logOut.bind(this)}>Log Out</Button>
              </div>
            :
            <div style={buttonGroupStyle}>
              <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            </div>
            }
          </div>
          }
        </header>
      </div>
    );
  }
}

export default App;
