import React from 'react';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';

const buttonStyle = {
  margin: '5px'
}
const buttonGroupStyle = {
  margin: '20px'
}

const shiftSelectorStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}

const hourToButtonNumberOG = {
  0: 0,
  2: 1,
  3: 2,
  4: 3
}

class ShiftSelector extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      yo: "tototot",
      totalTime: 50,
      myID: "zl1234",
      buttonSelected: 0,
      loading: true,
      secondaryButton: [],
      myStartHour: "",
      myEndHour: "",
      startHourButtonId: -1,
      mytime: 0,
      startHourSelected: "",
      startHours:[],
      shiftLengths: [],
      modifyButtonClicked: false,
      transactionCompleted: true,
      hourToButtonNumber: hourToButtonNumberOG,
      selectedShiftId: ""
    }
  }

  dictToHourToButtonNumber = (listShiftLengths) => {
    let hourToButtonNumber = {0:0};
    let index = 1;
    listShiftLengths.forEach((elem) => {
      hourToButtonNumber[elem] = index;
      index += 1;
    });
    return hourToButtonNumber;
  }

  shouldComponentUpdate(props, state){
    if (this.props !== props){
      var db = props.db;
      var refStr = props.refStr;
      var ref = db.ref(refStr);
      this.setState({loading: true});
      ref.child("infos").on("value", (snapshot) => {
        var values = snapshot.val();
        if (values){
          let hourToButtonNumber = this.dictToHourToButtonNumber(values.shiftLengths);
          values.hourToButtonNumber = hourToButtonNumber;
          this.setState(values);
        }
      }, function (errorObject) {
      });
      const myID = props.myID;
      ref.child('list/'+myID).on("value", (snapshot) =>{
        const value = snapshot.val();
        if (value){
          this.setState({
            buttonSelected: this.state.hourToButtonNumber[value.mytime],
            mytime: value.mytime,
            myStartHour: value.myStartHour,
            myEndHour: value.myEndHour,
            myID: props.myID,
            transactionCompleted: value.transactionCompleted,
            loading: false
          });
        }
        else {
          this.setState({
            buttonSelected: 0,
            mytime: 0,
            myStartHour: "",
            myEndHour: "",
            myID: this.props.myID,
            transactionCompleted: true,
            loading: false
          });
        }
      });

      if(props.selectedShiftId != props.shiftId){
        this.setState({secondaryButton: [], startHourButtonId: -1, startHourSelected: ""});
      }
    }
    return true;
  }

  componentDidMount() {
    var db = this.props.db;
    var refStr = this.props.refStr;
    var ref = db.ref(refStr);
    
    ref.child("infos").on("value", (snapshot) => {
      var values = snapshot.val();
      if (values){
        let hourToButtonNumber = this.dictToHourToButtonNumber(values.shiftLengths);
        values.hourToButtonNumber = hourToButtonNumber;
        this.setState(values);
      }
    }, function (errorObject) {
    });
    const myID = this.props.myID;
    ref.child('list/'+myID).on("value", (snapshot) =>{
      const value = snapshot.val();
      if (value){
        this.setState({
          buttonSelected: this.state.hourToButtonNumber[value.mytime],
          mytime: value.mytime,
          myStartHour: value.myStartHour,
          myEndHour: value.myEndHour,
          myID: this.props.myID,
          transactionCompleted: value.transactionCompleted,
          loading: false
        });
      } else {
        this.setState({
          loading: false
        })
      }
    });

    /*
    const shiftList = this.props.shiftList;
    var allShiftRef = this.props.allShiftRef;
    
    Promise.all(shiftList.map((shiftId) => {
      return new Promise(async(resolve, reject) => { 
        var ref = db.ref(allShiftRef+"/"+shiftId+"/infos");
        ref.once("value", snapshot=>{
          let shift = {}
          var values = snapshot.val();
          if (values != null){
            shift['date'] = values.date;

            ref = db.ref(allShiftRef+"/"+shiftId+"/list/"+myID);
            ref.on("value", shiftSnap => {
              let shiftValues = shiftSnap.val();
              if (shiftValues != null && shiftValues.mytime != null){
                  shift['myStartHour'] = shiftValues.myStartHour
                  shift['myEndHour'] = shiftValues.myEndHour
              }
              this.setState({[shiftId]: shift})
              resolve();
            })
          }
        })
      });
    })).then(
      () => {
        this.setState({loading: false})
      });
    */
  }

  constructDateStringFromDate = (date) => {
    var dateYear = date.getFullYear();
    var dateMonth = date.getMonth() + 1; //months are from 0-11
    dateMonth = dateMonth < 10 ? "0"+dateMonth : dateMonth;
    var dateDay = date.getDate();
    dateDay = dateDay < 10 ? "0"+dateDay : dateDay;

    return dateYear + "-" + dateMonth + "-" + dateDay;
  }

  addZeroToSingleDigitHours = (hour) => {
    if (hour >= 0 && hour <= 9){
      return "0"+hour;
    }
    return ""+hour;
  }

  takeshift = (n, buttonNb, startHour, myEndHour) => {
    const myID = this.props.myID;

    var refStr = this.props.refStr;
    var listRef = this.props.db.ref(refStr+"/list/"+myID);
    this.setState({buttonSelected: buttonNb, mytime:n});
    listRef.set(
      {
        "mytime":n, 
        myStartHour: startHour, 
        myEndHour:myEndHour, 
        displayName: this.props.displayName, 
        email: this.props.email, 
        transactionCompleted: false,
        carSize: this.props.carSize
      })
  }

  emptyButtonAndTakeShift = (n, buttonNb, startHour, myEndHour) => {
    this.setState({secondaryButton: [], startHourSelected: "", startHourButtonId: -1, modifyButtonClicked: false});
    this.takeshift(n, buttonNb, startHour, myEndHour);
  }
  refreshAndTakeShift = async (startHour, n, buttonNb, myEndHour) => {
    await this.emptyButtonAndTakeShift(n, buttonNb, startHour, myEndHour);
  }

  createButton = (startHour, endHour, startHourButtonId, shiftsDatesHours) => {
    return <Button variant="contained" color={startHourButtonId === this.state.startHourButtonId ? "secondary" :"primary"} onClick={this.createToTimeButton.bind(this, startHour, endHour, startHourButtonId, shiftsDatesHours)} style={buttonStyle}>
             {startHour}
           </Button>
  }

  stringTimeAddHour = (strTime, addedHour) => {
    var hourNb = parseInt(strTime.slice(0,2));
    var finalHour = (hourNb + addedHour) % 24;
    var minutesStr = strTime.slice(3,5);

    return this.addZeroToSingleDigitHours(finalHour) + ":" + minutesStr;
  }

  createToTimeButton = (startHour, endHour, startHourButtonId, shiftsDatesHours) => {
    this.props.changeSelectedShiftId(this.props.shiftId);
    const remainingTime = this.state.remainingTime;
    const mytime = this.state.mytime;
    const shiftLengths = this.state.shiftLengths;
    var choices = shiftLengths.filter((number)=>{
      return number <= remainingTime + mytime;
    });
    var currentStartHour = this.state.myStartHour;
    var startHourNb = parseInt(startHour.slice(0,2));
    var startMinutesStr = startHour.slice(3,5);
    var startMinutesNb = parseInt(startMinutesStr);
    var arrButtons = [];
    arrButtons.push(<h5>End Time</h5>);

    var allOverlapping = true;
    var atLeastOneValidChoice = false;
    for(var choice of choices){
      var endHourNb = parseInt(endHour.slice(0,2));
      var endMinutesNb = parseInt(endHour.slice(3,5));
      var endHourCalculated = startHourNb + choice;
      
      if (endHourCalculated > endHourNb) continue;
      else if(endHourCalculated === endHourNb && endMinutesNb < startMinutesNb) continue;
      atLeastOneValidChoice = atLeastOneValidChoice || true;
      var endHourCalculatedStr = `${this.addZeroToSingleDigitHours(endHourCalculated)}:${startMinutesStr}`

      let isOverlapping = this.isShiftHourBetweenTakenShifts(shiftsDatesHours, this.state.date, endHourCalculatedStr)

      let isContaining = this.isShiftStartAndEndContainingAnotherTakenShifts(shiftsDatesHours, this.state.date, startHour, endHourCalculatedStr)
      
      allOverlapping = allOverlapping && (isOverlapping || isContaining)//will be true only if every end time overlaps/contains
      
      if (!isOverlapping && !isContaining){
        var button = 
        <Button variant="contained" disabled={this.state.buttonSelected === this.state.hourToButtonNumber[choice] && currentStartHour !== "" && startHour === currentStartHour} color="primary" onClick={this.refreshAndTakeShift.bind(this, startHour ,choice, this.state.hourToButtonNumber[choice], endHourCalculated+":"+startMinutesStr)} style={buttonStyle}>
          {this.addZeroToSingleDigitHours(endHourCalculated)+":"+startMinutesStr+" ("+choice+"h)"}
        </Button>

        arrButtons.push(button);
      }
    }
    if (allOverlapping && atLeastOneValidChoice){
      arrButtons = (<p>
                This shift overlaps with one of your current shifts
              </p>)
      this.setState({secondaryButton: arrButtons}); //not selecting start hour
    }
    else{
      this.setState({secondaryButton: arrButtons, startHourButtonId: startHourButtonId, startHourSelected: startHour});
    }
    
  }

  getSnapshotBeforeUpdate(nextProps, nextState) {
    if (this.state.startHourSelected !== "" && nextState.remainingTime !== this.state.remainingTime){
      this.createToTimeButton(nextState.startHourSelected, nextState.endTime, nextState.startHourButtonId);
    }
  }

  createShiftStartTimeButtons = (startHours, shiftsDatesHours) => {
    var startHoursButtons = [];
    startHoursButtons.push(<h5>Start time</h5>);
    var allOverlapping = true;
    for(var startTime of startHours){
      let isOverlapping = this.isShiftHourBetweenTakenShifts(shiftsDatesHours, this.state.date, startTime)
      allOverlapping = allOverlapping && isOverlapping //will be true only if every start time overlaps
      
      if (!isOverlapping){
        startHoursButtons.push(this.createButton(startTime, this.state.endTime, startTime, shiftsDatesHours));
      }
    }
    if (allOverlapping){
      return (<p>
                This shift overlaps with one of your current shifts
              </p>)
    }

    return startHoursButtons;
  }

  toggleModifyButton = () => {
    var currentModifyState = this.state.modifyButtonClicked;
    this.setState({modifyButtonClicked: !currentModifyState});
  }

  isRemainingTimeLessThanMinimum(remainingTime,shiftLengths){
    return remainingTime < Math.min(...shiftLengths);
  }

  getMyCurrentShiftsFromState() {
    let shiftList = this.props.shiftList;
    let myShiftList = [];
    shiftList.forEach(shiftId => {
      let singleShift = this.state[shiftId]
      if (singleShift != null && singleShift.myStartHour != null && singleShift.myStartHour != ""){
        myShiftList.push(singleShift);
      }
    });
    return myShiftList;
  }

  extractDateHourFromShifts(rawShifts) {
    let shifts = [];
    rawShifts.forEach(singleShift => {
      if (singleShift['shiftId'] != this.props.shiftId){
        let shift = {}
        //console.log(singleShift)
        shift['startDateHour'] = this.convertStringDateHoursIntoObj(singleShift.date, singleShift.myStartHour)
        shift['endDateHour'] = this.convertStringDateHoursIntoObj(singleShift.date, singleShift.myEndHour)
        shifts.push(shift)
      }
    })
    return shifts;
  }

  convertStringDateHoursIntoObj(shiftDate, shiftHour){
    if (shiftDate !== undefined){
      var shiftDateArr = shiftDate.split("-")
      shiftDateArr[1] = shiftDateArr[1]-1; // months are from 0-11
      if (shiftHour !== undefined){
        shiftDateArr[2] = shiftDateArr[2]; 
        var shiftHourArr = shiftHour.split(":")
        shiftDateArr = shiftDateArr.concat(shiftHourArr)
      }
      var shiftDateObj = new Date(...shiftDateArr);

      return shiftDateObj;
    }
    return undefined
  }

  isShiftHourBetweenTakenShifts(shifts, shiftDate, shiftHour){
    let isOverlapping = false;
    if (shiftDate !== undefined){
      let currentShiftObj = this.convertStringDateHoursIntoObj(shiftDate, shiftHour)

      for (let shift of shifts) {
        let takenShiftStart = shift.startDateHour;
        let takenShiftEnd = shift.endDateHour;

        if (currentShiftObj < takenShiftEnd && currentShiftObj > takenShiftStart){
          isOverlapping = true;
          break;
        }
      }
    }
    return isOverlapping;
  }

  isShiftStartAndEndContainingAnotherTakenShifts(shifts, shiftDate, shiftStartHour, ShiftEndHour){
    let isContaining = false;
    if (shiftDate !== undefined){
      let currentShiftStartObj = this.convertStringDateHoursIntoObj(shiftDate, shiftStartHour)
      let currentShiftEndObj = this.convertStringDateHoursIntoObj(shiftDate, ShiftEndHour)

      for (let shift of shifts) {
        let takenShiftStart = shift.startDateHour;
        let takenShiftEnd = shift.endDateHour;

        if (currentShiftEndObj >= takenShiftEnd && currentShiftStartObj <= takenShiftStart){
          isContaining = true;
          break;
        }
      }
    }
    return isContaining;
  }


  isCurrentDateEarlierThanShiftDate(shiftDate, shiftHour){
    if (shiftDate !== undefined){
      var shiftDateArr = shiftDate.split("-")
      shiftDateArr[1] = shiftDateArr[1]-1; // months are from 0-11
      if (shiftHour !== undefined){
        // going for same hour yesterday
        shiftDateArr[2] = shiftDateArr[2]-1; // getting 24h before and we can have 0 and negative days
        var shiftHourArr = shiftHour.split(":")
        //console.log(shiftDateArr, shiftHourArr)
        //console.log(shiftDateArr.concat(shiftHourArr))
        shiftDateArr = shiftDateArr.concat(shiftHourArr)
      }
      var shiftDateObj = new Date(...shiftDateArr);
      //console.log(shiftDateObj)
      var now = new Date();
      return now < shiftDateObj;
    }
    return false;
  }

  render() {
    if(this.state.workDay !== undefined && !(this.state.workDay).startsWith(this.props.selectedWorkday)){
      return "";
    }
    let myShiftList = this.props.currentSelfShifts /*this.getMyCurrentShiftsFromState();*/
    let shiftsDatesHours = this.extractDateHourFromShifts(myShiftList);
    //console.log(shiftsDatesHours)
    //let isOverlapping = this.isShiftHourBetweenTakenShifts(shiftsDatesHours, this.state.date, this.state.startTime)
    //if (isOverlapping)  console.log("OVERLAPPING", this.state.date, this.state.startTime)

    const showStartTimes = this.state.myStartHour === "" || this.state.modifyButtonClicked === true;
    const isNoMoreTimeAvailable = (this.isRemainingTimeLessThanMinimum(this.state.remainingTime, this.state.shiftLengths) || 
                                  this.state.remainingTime <= 0) && 
                                  this.state.mytime === 0;
    //console.log(this.state.startTime)
    var canModifyAndCancel = this.isCurrentDateEarlierThanShiftDate(this.state.date, this.state.startTime);
    var canAddNewHours = this.isCurrentDateEarlierThanShiftDate(this.state.date)
    return (
      <div style={shiftSelectorStyle}>
      {
        this.state.loading || this.state.transactionCompleted === false ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={100000} //100 secs
        />
        :
      <div>
        <h3> {this.state.date} </h3>
        <h4> {this.state.workDay} from {this.state.startTime} to {this.state.endTime} </h4>
        {this.state.myStartHour === "" ? "" : 
          <div>
            <h5 style={{color: "green", marginBottom: "0px"}}> MY CHOICE:</h5>
            <h5 style={{color: "green", marginTop: "0px"}}><strong>{this.state.myStartHour}</strong> to <strong>{this.state.myEndHour} ({this.state.mytime}H)</strong> 
            {canModifyAndCancel ? 
              <a title="Cancel shift" style={{color:"red", marginLeft:"5px", textDecoration: "None"}} onClick={this.emptyButtonAndTakeShift.bind(this,0,0,"","")} href="javascript:void(0);">x</a> // eslint-disable-line jsx-a11y/anchor-is-valid, eqeqeq, no-script-url
              :""
            }
            </h5> 
          </div>
        }
        { 
        
            isNoMoreTimeAvailable  ? 
              <p>
                No more hours available
              </p>
              : 
              <p>
                {this.state.remainingTime > 0 ? this.state.remainingTime + " hours still" : "No more hours"}  available 
              </p>
        }
        { isNoMoreTimeAvailable ? "" :
        ! canAddNewHours ? "To add/modify hours, please contact us" :
        showStartTimes ? 
          <div>
            <div style={buttonGroupStyle}>
              
              {this.createShiftStartTimeButtons(this.state.startHours, shiftsDatesHours)}
            </div>
            <div style={buttonGroupStyle}>
              {this.state.secondaryButton.length === 0  ? ""
              : 
                <div>
                {this.state.secondaryButton}
                </div>
              }
            </div>
          </div>
        :
        <div style={buttonGroupStyle}>
          {canModifyAndCancel ? 
          <Button variant="contained" color="primary" onClick={this.toggleModifyButton.bind(this)} style={buttonStyle}>
            Modify my shift
          </Button>
          : "To modify hours, please contact us" 
          }
          
        </div>
        }
      </div>
      }
      </div>
    );
  }
}

export default ShiftSelector;
