import React from 'react';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';

const shiftViewerStyle = {
    border: '1px white solid',
    marginBottom: '20px',
    marginTop: '20px',
    padding: '30px'
  }
class SubscribeListViewer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        loading: true,
        subscribeList: {},
        open: false
    }
  }

  shouldComponentUpdate(props, state){
    if (this.props !== props){
        var db = props.db;
        const dayStr = props.dayStr;
        var refStr = props.refStr;

        var ref = db.ref(refStr+"/"+dayStr);
        ref.on("value", snapshot => {
            var value = snapshot.val();
            //console.log(value)
            if (value != null){
                //console.log("I AM SUBSCRIBED")
                this.setState({
                    subscribeList: value,
                    loading: false
                })
            } else {
                this.setState({
                    subscribeList: {},
                    loading: false
                })
            }
        })
    }
    return true;
  }

  componentDidMount() {
    var db = this.props.db;
    const dayStr = this.props.dayStr;
    var refStr = this.props.refStr;

    var ref = db.ref(refStr+"/"+dayStr);
    ref.on("value", snapshot => {
        var value = snapshot.val();
        //console.log(value)
        if (value != null){
            //console.log("I AM SUBSCRIBED")
            this.setState({
                subscribeList: value,
                loading: false
            })
        } else {
            this.setState({
                subscribeList: {},
                loading: false
            })
        }
    })
  }

  transformSubscribeObjectToList = () => {
    let subscribeList = [];
    for (let key in this.state.subscribeList){
        subscribeList.push(this.state.subscribeList[key])
    }
    return subscribeList;
  }

  generateHtmlObjectFromList = (subscribeList) => {
    let htmlObjs = []
    subscribeList.forEach(element => {
        let row = <p>{`${element.name} (${element.email})`}</p>
        htmlObjs.push(row);
    });
    return htmlObjs;
  }

  copyText = (copyStr) => {
    navigator.clipboard.writeText(copyStr);
    this.setState({open: true})
  }
  onOpen = () => {
    this.setState({open: false})
  }
  render() {
    let dateStr = this.props.dayStr;
    let dateArr = dateStr.split("-")
    dateArr[1] = dateArr[1]-1;
    let date = new Date(...dateArr)
    let selectedDay = this.props.selectedWorkdayNo
    //console.log (date.getDay(), date, selectedDay)

    let subscribeList = this.transformSubscribeObjectToList();
    let copyStr = subscribeList.map(elem=>elem.email).join(",");
    let subscribeListView = this.generateHtmlObjectFromList(subscribeList);
    //console.log(date, subscribeList)
    return (<div style={{marginTop:"50px"}}>
        
        {date.getDay() === selectedDay ? 
            this.state.loading === false ? 
            <div style={shiftViewerStyle}>
                <h2>Stand-by driver list</h2> 
                <h3>({dateStr})</h3> 
                <Tooltip
                    title="Copied!"
                    open={this.state.open}
                    leaveDelay={200}
                    placement="right"
                    onOpen={this.onOpen.bind(this)}
                >
                <Button onClick={this.copyText.bind(this, copyStr)} variant="contained" color="primary">Copy emails</Button>
                </Tooltip>
                { subscribeList.length > 0 ? subscribeListView:
                <p>No driver has subscribed to this day yet</p>}
            </div>
            :
            <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={100000} //100 secs
            />
        :""}
        
    </div>);
  }
}

export default SubscribeListViewer;
