import React from 'react';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as req from 'request-promise'; 

const driverViewerStyle = {
  border: '1px white solid',
  marginBottom: '20px',
  marginTop: '20px',
  padding: '30px'
}


class ExportView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
      city: "mtl"
    }
  }

  
  componentDidMount() {
  }

  handleFromDateChange = event => {
    this.setState({fromDate: event.target.value});
  };

  handleToDateChange = event => {
    this.setState({toDate: event.target.value});
  };

  handleCityChange = event =>{
    this.setState({city: event.target.value});
  }

  generateReport = async (event) => {
      this.setState({loading: true})
      await req.get(`https://api.boxknight.com/v1/schedulerexport?from=${this.state.fromDate}&to=${this.state.toDate}&city=${this.state.city}`, {encoding: null})
      .then(response => {
        let data = new Blob([response], { type: "text/csv" });
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${this.state.city}_${this.state.fromDate}-${this.state.toDate}.csv`;
        a.click();
        this.setState({loading: false})
        }).catch((e)=>{console.log(e)})
      console.log(this.state)
  }

  render() {
    return (
      <div>
        { this.state.loading ? 
        <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
        />
        :
        <div>
        <TextField
            style={{backgroundColor: "white", marginRight: "5px", padding: "5px"}}
            id="date"
            label="From"
            type="date"
            value={this.state.fromDate}
            InputLabelProps={{
            shrink: true,
            }}
            onChange={this.handleFromDateChange.bind(this)}
        />
        <TextField
            style={{backgroundColor: "white", marginRight: "5px", padding: "5px"}}
            id="date"
            label="To"
            type="date"
            value={this.state.toDate}
            onChange={this.handleToDateChange.bind(this)}
            InputLabelProps={{
            shrink: true,
            }}
        />
        <Select
        style = {{backgroundColor: "white", marginRight: "5px", padding: "13px"}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.city}
          onChange={this.handleCityChange.bind(this)}
        >
          <MenuItem value={"mtl"}>Montreal</MenuItem>
          <MenuItem value={"to"}>Toronto</MenuItem>
          <MenuItem value={"mtlvans"}>Montreal Vans</MenuItem>
          <MenuItem value={"tovans"}>Toronto Vans</MenuItem>
          <MenuItem value={"qcc"}>Quebec</MenuItem>
          <MenuItem value={"ott"}>Ottawa</MenuItem>
          <MenuItem value={"van"}>Vancouver</MenuItem>
        </Select>
        <Button onClick={this.generateReport.bind(this)} style={{verticalAlign: "bottom", padding: "17px"}} variant="contained" color="primary">Generate</Button>
        </div>
        
        }
      </div>
    );
  }
}

export default ExportView;
